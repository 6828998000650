import * as React from "react";
import * as Rebass from 'rebass';
//import fuzzyFilterFactory, { onChangeInputValue } from "react-fuzzy-filter";
import styled from 'styled-components';

// these components share state and can even live in different components
//const { InputFilter, FilterResults, changeInputValue } = fuzzyFilterFactory();

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container } from '../../components/container'
import { Wrapper } from '../../components/wrapper';

const PageTitle = styled(Rebass.Text).attrs({
  fontFamily: 'Avenir',
  fontSize: [5]
})``

const PageHeader = styled(Rebass.Flex).attrs({
  flexDirection: 'column',
  py: 4
})``

const IndexPage = () => {
  return (
    <Layout>

      <SEO title="Resources" />

      <Wrapper>
        <PageHeader>
          
          <PageTitle>
            Resources
          </PageTitle>

        </PageHeader>
      </Wrapper>

    </Layout>
  );
}
export default IndexPage;
